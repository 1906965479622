import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig} from '@angular/material/dialog';

import { ModalActionsService } from './modal-actions.service';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ModalComponent>,
              @Inject(MAT_DIALOG_DATA) public modalData: any,
              private modalService: ModalActionsService) {
  }

  static openModalError(matDialog, errorMessage, dataAction = 'reload') {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.panelClass = 'myapp-no-padding-dialog';
    // dialogConfig.height = '350px';
    dialogConfig.width = '600px';
    dialogConfig.data = {
      action: dataAction,
      title: 'Attenzione',
      message: errorMessage,
      actionButtonText: 'OK',
      newRoute: ''
    };
    matDialog.open(this, dialogConfig);
  }

  ngOnInit() {
  }

  actionFunction() {
    this.modalService.modalAction(this.modalData);
    this.closeModal();
  }

  closeModal() {
    if(this.modalData.action !== undefined){
      this.modalService.modalAction(this.modalData);
    }
    this.dialogRef.close();
  }

}
