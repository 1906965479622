import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { ApiModule, BASE_PATH } from 'lms-api';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { FallbackComponent } from './fallback.component';
import { NotFoundComponent } from './notfound.component';
import { ShouldLoginComponent } from './should-login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { ErrorDialogComponent } from './error-dialog/errordialog.component';
import { ModalResetPasswordComponent } from '../app/components/modal-reset-password/modal-reset-password.component';
import { ModalResetPasswordActionsService } from '../app/components/modal-reset-password/modal-reset-password-actions.service';
import { ErrorDialogService } from './error-dialog/errordialog.service';
import { MyMaterialModule } from './material.module';
import { FooterComponent } from './components/footer/footer.component';
import {NgTinyUrlModule} from 'ng-tiny-url';
import { ModalUtenteEsistenteComponent } from './components/modal-utente-esistente/modal-utente-esistente.component';
import { ModalCreateCardComponent } from './components/modal-create-card/modal-create-card.component';
import { ModalAddBasketComponent } from './components/modal-add-basket/modal-add-basket.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import { ModalRemoveBasketitemComponent } from './components/modal-remove-basketitem/modal-remove-basketitem.component';
import { StripeModule } from 'stripe-angular';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [
    AppComponent,
    FallbackComponent,
    NotFoundComponent,
    ShouldLoginComponent,
    ErrorDialogComponent,
    ModalResetPasswordComponent,
    FooterComponent,
    ModalUtenteEsistenteComponent,
    ModalCreateCardComponent,
    ModalAddBasketComponent,
    ModalRemoveBasketitemComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule.forRoot(),
    AppRoutingModule,
    ApiModule,
    HttpClientModule,
    MyMaterialModule,
    NgTinyUrlModule,
    FlexLayoutModule,
    StripeModule.forRoot('')
  ],
  providers: [
    ApiModule,
    ErrorDialogService,
    ModalResetPasswordActionsService,
    CookieService,
    { provide: BASE_PATH, useValue: environment.lmsApiBasePath },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


