import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private readonly router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    /*RW23-34 Nuova implementazione per gestire il redirect se non loggato */
    this.authService.canActivateProtectedRoutes$.subscribe(authenticated =>
      {
        if (!authenticated){
          this.router.navigate(['login']);
        }
      }
    );
    /*RW23-34 Nuova implementazione per gestire il redirect se non loggato */
    return this.authService.canActivateProtectedRoutes$;
  }
}
