<div id="page-container">
  <div id="content-wrap">
    <!-- all other page content -->
    <router-outlet></router-outlet>   
    <mat-progress-spinner class="authenticating-loader" *ngIf="!(isDoneLoading | async)" color="warn" 
      diameter="100" mode="indeterminate" strokeWidth="true" value="50">
    </mat-progress-spinner>
  </div>
  <app-footer></app-footer>
</div>
