<div class="bg-gray footer-padding footer-xs" style = "position: sticky; z-index: 1000;">
  <div class="footer-firstline">
    <div class="footer-fc">
      <div class="fl-fc">
        ROCKWOOL Italia S.p.A
      </div>
      <div class="fl-sc">
        Via Canova, 12 <br>
        20145 - Milano <br>
        P.IVA 01980790925
      </div>
      <div class="fl-tc">
        Tel.  02 0346 131
      </div>
    </div>
    <div class="footer-sc">
      Per informazioni e dettagli aggiuntivi scrivi a: <a class="text-white" href="mailto:'riqualificare@rockwool.com'">riqualificare@rockwool.com</a>
    </div>
  </div>
  <hr/>
  <div class="footer-secondline">
    <!--<div class="sl-fc">Note Legali | Informativa sulla privacy | I nostri brand | Cookie Declaration </div>-->
    <div class="sl-fc"></div>
    <div class="sl-sc"><a href="../../../assets/pdf/RockWin_Regolamento.pdf" target="_blank">Regolamento</a> | Copyright &copy; 2023 ROCKWOOL Italia</div>
  </div>
</div>


