
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AcelMGMService } from 'lms-api';
import { ModalComponent } from '../modal/modal.component';
import { ModalAddBasketService } from './modal-add-basket.service';

@Component({
  selector: 'app-modal-add-basket',
  templateUrl: './modal-add-basket.component.html',
  styleUrls: ['./modal-add-basket.component.scss']
})
export class ModalAddBasketComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ModalAddBasketComponent>,
              @Inject(MAT_DIALOG_DATA) public modalData: any,
              public matDialog: MatDialog) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.dialogRef.close();
  }
  /* se si vuole aprire un altra modale nella modale
  openModalAddBasket() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.panelClass = 'myapp-no-padding-dialog';
    // dialogConfig.height = '350px';
    dialogConfig.width = '600px';
    dialogConfig.data = {
      title: 'OPERAZIONE EFFETTUATA',
      actionButtonText: 'OK',
      message: 'Codice Amico creato correttamente, puoi trovare la posizione del cliente dalla maschera di ricerca'
     // newRoute: ''
    };
    const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
  }*/

}
