import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-modal-remove-basketitem',
  templateUrl: './modal-remove-basketitem.component.html',
  styleUrls: ['./modal-remove-basketitem.component.scss']
})
export class ModalRemoveBasketitemComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ModalRemoveBasketitemComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    public matDialog: MatDialog) { }

  ngOnInit(): void {
  }

  closeModal(resp) {
    this.dialogRef.close(resp);
  }
}
