// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  lmsApiBasePath: 'https://test.api.lmscloud.it',
  issuer: 'https://test.identity.lmscloud.it',
  // lmsApiBasePath: 'https://localhost:5001', // 'https://test.api.lmscloud.it',
  // issuer: 'https://localhost:5003', //'https://test.identity.lmscloud.it',
  clientId: 'boPWniz4X0SgJ1yu2DNE4d4FkZPqEHLF', // 'P3TV0v4gHKESSMjiXkgs26I3KDiVkMsO'
  // lmsApiBasePath: 'https://localhost:5001',
  clientSecret: 'IhTVPK34zjLWadtSAyr8HfhE2f99w4vH',
  campaign_id: '6E7A410A-A9C0-40CA-A56A-E3C791E173DD',
  domain: 'https://test.rockwool.lmscloud.it/',
  thankyou: 'https://test.rockwool.lmscloud.it/checkout',
  client_rw : 'a73821ec-6530-412e-8b64-a70b3505bb76',
  designer_rw: '1f528796-28ce-4c93-9ad6-5a259ab6bdc1',
  site_progettisti: 'http://rockwool2k22.studiocm.net/designers-portal/user/login'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
