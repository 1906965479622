import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/auth-guard.service';
import { FallbackComponent } from './fallback.component';
import { NotFoundComponent } from './notfound.component';
import { ShouldLoginComponent } from './should-login.component';



const routes: Routes = [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
     // { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
      { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
      { path: 'register', loadChildren: () => import('./register/register.module').then(m => m.RegisterModule) },
      { path: 'confirm-email', loadChildren: () => import('./confirm-email/confirm-email.module').then(m => m.ConfirmEmailModule) },
      { path: 'reset-password', loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
      { path: '', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
        canActivate: [AuthGuard], runGuardsAndResolvers: 'always'},
      { path: 'should-login', component: ShouldLoginComponent },
      { path: '404', component: NotFoundComponent },
      { path: '**', redirectTo: '404' } 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
