<div id="modal-content-wrapper">
    <header id="modal-header">
      <button class="close" mat-button (click)="closeModal(false)">X</button>
      <h4 class="numberstitle" id="modal-title">{{ modalData.title }}</h4>
    </header>
  
    <div class="filocolorato"></div>
    <section id="modal-body">
      {{modalData.message}}
    </section>
    <footer id="modal-footer"  fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <button mat-stroked-button class="button-amici btn-text mx-10  second-color" (click)="closeModal(false)">
                {{ modalData.actionButtonCancel }}
            </button>
        </div>
        <div>
            <button mat-stroked-button class="button-amici btn-text mx-10 " (click)="closeModal(true)">
                {{ modalData.actionButtonTextContinue }}
            </button>
        
        </div>
      
    </footer>
  </div>
  