import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ErrorDialogComponent } from './errordialog.component';

@Injectable()
export class ErrorDialogService {
    // public isDialogOpen = false;
    constructor(public dialog: MatDialog, private router: Router) { }
    openDialog(data): any {
        /* if (this.isDialogOpen) {
            return false;
        } */
        // this.isDialogOpen = true;
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
            disableClose: true,
            panelClass: 'myapp-no-padding-dialog',
            width: '600px',
            data
        });

        /* dialogRef.afterClosed().subscribe(result => {
            this.isDialogOpen = false;
            // capire se va bene pulire il localstorage
            // localStorage.clear();
            // this.router.navigateByUrl('/home');
        });*/
    }
}
