<div id="modal-content-wrapper">
  <header id="modal-header">
    <button class="close" mat-button (click)="closeModal()">X</button>
    <h4 class="numberstitle" id="modal-title">{{ modalData.title }}</h4>
  </header>

  <div class="filocolorato"></div>
  <section id="modal-body">
    {{modalData.message}}
  </section>
  <footer id="modal-footer"  fxLayout="row" fxLayoutAlign="space-between center">
    <div>
    <a
      *ngIf="modalData.actionButtonTextContinue"
      href="{{modalData.newRouteContinue}}"
      mat-stroked-button class="button-amici btn-text mx-10 second-color"
    >
      {{ modalData.actionButtonTextContinue }}
    </a>
    </div>
    <div>
      <a
        href="{{modalData.newRouteCart}}"
        *ngIf="modalData.actionButtonTextCart"
        mat-stroked-button class="button-amici btn-text mx-10"
      >
        {{ modalData.actionButtonTextCart }}
      </a>
    </div>
  </footer>
</div>
