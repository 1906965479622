import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalUtenteEsistenteService } from './modal-utente-esistente.service';

@Component({
  selector: 'app-modal-utente-esistente',
  templateUrl: './modal-utente-esistente.component.html',
  styleUrls: ['./modal-utente-esistente.component.scss']
})
export class ModalUtenteEsistenteComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ModalUtenteEsistenteComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private modalService: ModalUtenteEsistenteService) { }

  ngOnInit(): void {
  }

  actionFunction() {
    this.modalService.modalAction(this.modalData);
    this.closeModal();
  }

  closeModal() {
    
    this.dialogRef.close();
    // window.location.reload();
  }

  closeModalAndReload() {
    this.dialogRef.close();
    window.location.reload();
  }

}
